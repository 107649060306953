import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GitHubIcon from '@mui/icons-material/GitHub';
import logo from "../assets/logo.png";

export default function Footer() {
  return (
    <div className="w-screen pt-3 lg-pt-10 bg-black text-white">
      <div className='text-lg flex items-center justify-center lg:text-3xl w-4/5 mx-auto my-10 font-semibold'>
        {/* Your header content goes here */}
              
      </div>

      <div className='flex flex-col lg:flex-row lg:justify-between w-4/5 mx-auto font-medium mb-5 lg:mb-10'>
        <div>
          {/* Your company information goes here */}
          <h1 className='decoration-blue-400 xl:text-2xl underline underline-offset-8 ' style={{color:"#9A50DF"}}>
              TheloopX Technologies Pvt Ltd</h1><p><br/>We can help you manage<br/> the AI Services and IT Services</p>
            
          
        </div>
        <div>
          <ul>
            {/* Your services list goes here */}
            <li className='lg:mb-2 mb-1 decoration-blue-400 xl:text-1xl underline underline-offset-8 '>Services</li>
            <li className='lg:mb-2 mb-1'>Design</li>
            <li className='lg:mb-2 mb-1'>Ai Models</li>
            <li className='lg:mb-2 mb-1'>Development</li>
            <li className='lg:mb-2 mb-1'>Adndroid </li>
            <li className='lg:mb-2 mb-1'>Tech Support</li>
           
          </ul>
        </div>
        <div>
          <ul>
            {/* Your firm 360 list goes here */}
            <li className='lg:mb-2 mb-1 decoration-blue-400 xl:text-1xl underline underline-offset-8 '>Firm 360</li>
            <li className='lg:mb-2 mb-1'>Highlights</li>
            <li className='lg:mb-2 mb-1'>StarXAiShow</li>
            <li className='lg:mb-2 mb-1'>Project Show</li>
          
          </ul>
        </div>
        <div>
          <ul>
            {/* Your more list goes here */}
            <li className='lg:mb-2 mb-1 decoration-blue-400 xl:text-1xl underline underline-offset-8'>More</li>
            <li className='lg:mb-2 mb-1'>Blog</li>
            <li className='lg:mb-2 mb-1'>Privacy Policy</li>
            <li className='lg:mb-2 mb-1'>Terms and Conditions</li>
           
          </ul>
        </div>
        <div>
          <ul>
            {/* Your contact us list goes here */}
            <li className='lg:mb-2 mb-1 decoration-blue-400 xl:text-1xl underline underline-offset-8'>ContactUs</li>
            <li className='lg:mb-2 mb-1'>Call +91- 6302440159</li>
            <li className='lg:mb-2 mb-1'>info@theloopx.com</li>
           
          </ul>
        </div>
      </div>

      <div className='flex flex-row pb-5 lg:pb-8 lg:pt-5 gap-2 lg:gap-10 justify-around w-4 items-center mx-auto'>
        <a href="https://www.instagram.com/theloopxtechnologies/" target="_blank">
          <InstagramIcon className='cursor-pointer hover:bg-blue-600 transition-all duration-200' fontSize='medium'/>
        </a>
        <a href="https://twitter.com/theloopxtech" target="_blank">
          <TwitterIcon className='cursor-pointer hover:bg-blue-600 transition-all duration-200' fontSize='medium'/>
        </a>
        <a href="https://www.linkedin.com/company/theloopxtechnologies/" target="_blank">
          <LinkedInIcon className='cursor-pointer hover:bg-blue-600 transition-all duration-200' fontSize='medium'/>
        </a>
        <a href="https://wa.me/+919963450008" target="_blank">
          <WhatsAppIcon className='cursor-pointer hover:bg-blue-600 transition-all duration-200' fontSize='medium'/>
        </a>
        <a href="https://github.com/subramanyamrekhandar" target="_blank">
          <GitHubIcon className='cursor-pointer hover:bg-blue-600 transition-all duration-200' fontSize='medium'/>
        </a>
      </div>

      <div className='flex justify-center items-center py-5'>
        <a className="outline-none" href="#">
          <h1 className='lg:text-1xl text-lg font-semibold' style={{ color: "#9A50DF" }}>
            ©2023-2024 Theloopx Technologies
          </h1>
        </a>
      </div>
    </div>
  );
}
