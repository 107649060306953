import About from "./components/about";
import ContactUs from "./components/contactus";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Services from "./components/services";
import Testimonials from "./components/testimonials";
  

function App() {
  return (
    <div  className="overflow-x-hidden">
     
      <Hero />
    <About />
        <Services  />
      <ContactUs  />
      {/* <Testimonials /> */}
   
      <Footer />

      

    </div>
  );
}

export default App;
